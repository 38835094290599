import { executeWithRetry } from '~/utils/executeWithRetry';
import type { CleverTapNotification } from '~/components/web-inbox/types';
import {
  mapMemberOnboardingDataToAnalytics,
  type RemoteConfig,
  flagsConfig,
  RewardSystemPromotions,
  wait,
  UserGroup,
} from '@tn/shared';

interface CustomNotificationEvent {
  msgId: string;
  pivotId?: string;
  wzrk_slideNo?: number;
  kv?: any;
  msgCTkv?: any;
}

interface CleverTapEventPayload {
  [key: string]: string | number | boolean | string[];
}

interface CleverTapProfile {
  Site?: {
    [key: string]: string | boolean | number | string[];
  };
}

interface CleverTap {
  event: {
    push(name: string, payload?: CleverTapEventPayload): void;
  };
  profile: {
    push(profile: CleverTapProfile): void;
  };
  onUserLogin: {
    push(profile: CleverTapProfile): void;
  };
  setLogLevel?: (level: number) => void;
  dismissSpamControl?: boolean;
  spa?: boolean;
  getInboxMessageUnreadCount?: () => number;
  getAllInboxMessages?: () => Record<string, CleverTapNotification>;
  markReadInboxMessage?: (messageId: string) => void;
  deleteInboxMessage?: (messageId: string) => Promise<void>;
  renderNotificationViewed?: (detail: CustomNotificationEvent) => void;
  renderNotificationClicked?: (detail: CustomNotificationEvent) => void;
}

declare global {
  interface Window {
    clevertap?: CleverTap & {
      account: { id: string }[];
      region?: string;
      privacy?: any[];
    };
    clevertapInitialized?: boolean;
  }
}

const REGION = 'us1';

const defaultUserProperties: Record<string, boolean> = {
  'MSG-email': true,
  'MSG-push': true,
  'MSG-sms': true,
  'MSG-whatsapp': true,
};

export default defineNuxtPlugin({
  name: 'clevertap',
  setup() {
    const config = useRuntimeConfig();
    const { $sentry, $getVariant } = useNuxtApp();

    if (!window.clevertap) {
      window.clevertap = {
        event: [],
        profile: [],
        onUserLogin: [],
        account: [],
        privacy: [],
      };

      window.clevertap.account.push({ id: config.public.cleverTapAccountId });
      window.clevertap.region = REGION;
      window.clevertap.dismissSpamControl = true;
      window.clevertap.spa = true;
      window.clevertap?.privacy?.push({
        optOut: false,
        useIP: false,
      });
    }

    let cleverTapLoadPromise: Promise<void> | null = null;

    const remoteConfig = useState<RemoteConfig>('remote-config');
    const isForYouPageEnabled = computed(
      () => remoteConfig?.value?.forYouPage?.isEnabled
    );
    const unreadMessagesCount = ref<number | undefined>(undefined);

    const inboxMessages = ref<
      Record<string, CleverTapNotification> | undefined
    >();

    const sortedMessages = ref<
      Record<string, CleverTapNotification> | undefined
    >();

    const isWebInboxPromotion = computed(() => {
      const variant = $getVariant(flagsConfig.REWARD_SYSTEM_PROMOTIONS);

      return variant.value === RewardSystemPromotions.WebInbox;
    });

    const { isImpersonating } = useImpersonation();
    const { uid, membership, memberUser, isRewardSystemUser } = useUserState();
    const logLevel = config.public.cleverTapLogLevel as 0 | 1 | 2 | 3 | 4;
    const { fetchVariantIfNeeded } = useClientVariantFetch();

    const getUnreadMessagesCount = (
      messages?: Record<string, CleverTapNotification>
    ): number | undefined => {
      if (!messages) {
        return;
      }

      return Object.values(messages).filter((message) => {
        return message.viewed === 0;
      }).length;
    };

    const getAllInboxMessagesWithRetry = async (): Promise<
      Record<string, CleverTapNotification> | undefined
    > => {
      return executeWithRetry(
        () => {
          if (!window.clevertap || !window.clevertap.getAllInboxMessages) {
            console.warn('CleverTap or getAllInboxMessages is unavailable.');
            return;
          }
          return window.clevertap.getAllInboxMessages();
        },
        (result) => result !== undefined && Object.keys(result).length > 0
      );
    };

    function loadCleverTapScript(): Promise<void> {
      if (cleverTapLoadPromise) {
        return cleverTapLoadPromise;
      }

      cleverTapLoadPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src =
          'https://d2r1yp2w7bby2u.cloudfront.net/js/clevertap.min.js';

        script.onload = () => {
          window.clevertapInitialized = true;
          if (window.clevertap) {
            window.clevertap.setLogLevel?.(logLevel);
            resolve();
          } else {
            console.error('CleverTap initialization failed.');
            $sentry.captureException(
              new Error('CleverTap initialization failed.')
            );
            reject();
          }
        };

        script.onerror = () => {
          console.error('Failed to load the CleverTap script.');
          $sentry.captureException(
            new Error('Failed to load the CleverTap script.')
          );
          reject();
        };

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript?.parentNode?.insertBefore(script, firstScript);
      });

      return cleverTapLoadPromise;
    }

    async function initializeCleverTap() {
      if (!isForYouPageEnabled.value) {
        return null;
      }

      if (!window.clevertapInitialized) {
        try {
          await loadCleverTapScript();
        } catch (error) {
          console.error('Failed to load the CleverTap script.');
          $sentry.captureException(error);
        }
      }
    }

    const triggerCampaignEvent = async () => {
      if (isImpersonating.value) {
        return;
      }

      if (!uid.value) {
        return;
      }

      const memberUserAnalytics = memberUser.value?.onboarding
        ? mapMemberOnboardingDataToAnalytics(memberUser.value?.onboarding)
        : null;

      const cleverTapMemberAnalytics = memberUserAnalytics
        ? Object.entries(memberUserAnalytics).reduce(
            (acc, [key, value]) => {
              acc['tn_' + key] = value;
              return acc;
            },
            {} as Record<string, unknown>
          )
        : null;

      try {
        window?.clevertap?.event.push('TN User Logged In', {
          ...(membership.value ? { tn_member_status: membership.value } : {}),
          ...(isWebInboxPromotion.value && {
            tn_web_inbox_promotion: isWebInboxPromotion.value,
          }),

          ...(cleverTapMemberAnalytics ? { ...cleverTapMemberAnalytics } : {}),
        });
      } catch (error) {
        console.error('Failed to trigger campaign event.', error);
        $sentry.captureException(error);
      }
    };

    const onUserLogin = (userId: string) => {
      if (isImpersonating.value) {
        return;
      }

      if (!window.clevertap) {
        return;
      }

      try {
        window.clevertap.onUserLogin.push({
          Site: {
            Identity: userId,
            ...(membership.value ? { tn_member_status: membership.value } : {}),
            ...defaultUserProperties,
          },
        });
      } catch (error) {
        console.error('Failed to trigger onUserLogin event.', error);
        $sentry.captureException(error);
      }
    };

    const sortMessages = (
      messages: Record<string, CleverTapNotification> | undefined
    ) => {
      if (!messages) return {};

      const finalSortedMessages = Object.entries(messages).sort(
        ([a], [b]) => Number(b.split('_')[0]) - Number(a.split('_')[0])
      );

      const finalMessages = Object.fromEntries(finalSortedMessages);

      return finalMessages;
    };

    const deleteAllMessages = async () => {
      if (
        !window.clevertap ||
        !window?.clevertap?.getAllInboxMessages ||
        !window.clevertap.deleteInboxMessage
      ) {
        return;
      }

      const inboxMessages = window?.clevertap?.getAllInboxMessages();

      if (!inboxMessages) {
        return;
      }

      for (const messageId in inboxMessages) {
        window?.clevertap?.deleteInboxMessage(messageId);
      }
    };

    const getAllInboxMessages = async () => {
      if (!window.clevertap || !window.clevertap.getAllInboxMessages) {
        return;
      }

      const messages = await getAllInboxMessagesWithRetry();
      inboxMessages.value = { ...messages };

      unreadMessagesCount.value = getUnreadMessagesCount(inboxMessages.value);

      return inboxMessages.value;
    };

    const markMessageAsRead = (messageId: string) => {
      if (!window.clevertap || !window.clevertap.markReadInboxMessage) {
        return;
      }

      window.clevertap.markReadInboxMessage(messageId);

      if (window?.clevertap?.getAllInboxMessages) {
        const rawMessages = window?.clevertap?.getAllInboxMessages();
        unreadMessagesCount.value = getUnreadMessagesCount(rawMessages);
      }
    };

    watch(
      () => inboxMessages.value,
      (newVal) => {
        sortedMessages.value = sortMessages(newVal);
      },
      { immediate: true, deep: true }
    );

    const notificationClicked = (messageId: string) => {
      if (!window?.clevertap?.renderNotificationClicked) {
        return;
      }

      window?.clevertap?.renderNotificationClicked({
        msgId: messageId,
        pivotId: 'wzrk_default',
      });
    };

    watch(
      [uid, isForYouPageEnabled],
      async ([newUid, newIsForYouPageEnabled]) => {
        if (newUid && newIsForYouPageEnabled) {
          await initializeCleverTap();

          // Fetch variant if user is reward system user
          if (isRewardSystemUser.value) {
            const variant = $getVariant(flagsConfig.REWARD_SYSTEM_PROMOTIONS);

            // If it is a reward system user it should have a variant assigned
            if (!variant?.value || variant.value === 'OFF') {
              await fetchVariantIfNeeded(newUid, UserGroup.RewardSystem);
            }
          }

          // It seems that there are some race conditions in the script added delays to avoid them.
          await wait(500);
          onUserLogin(newUid);
          await triggerCampaignEvent();
          await wait(500);
          await getAllInboxMessages();
        }
      },
      { immediate: true }
    );

    watch(
      membership,
      async (newMembership, oldMembership) => {
        if (!newMembership || isImpersonating.value) {
          return;
        }

        if (oldMembership && newMembership && newMembership !== oldMembership) {
          await initializeCleverTap();
          await wait(500);
          window?.clevertap?.profile.push({
            Site: {
              ...(uid.value ? { Identity: uid.value } : {}),
              tn_member_status: newMembership,
              ...defaultUserProperties,
            },
          });

          await triggerCampaignEvent();
          await wait(500);
          await getAllInboxMessages();
        }
      },
      { immediate: true }
    );

    return {
      provide: {
        webInboxMessages: computed(() => sortedMessages.value),
        markMessageAsRead,
        unreadMessagesCount: computed(() => unreadMessagesCount.value),
        notificationClicked,
      },
    };
  },
});
